import { makeStyles } from '@material-ui/styles';
import theme from '../../../theme';
import { useEffect, useState } from 'react';
import useSessionStore from '@services/stores/SessionStore';
import useApplicationStore from '@services/stores/ApplicationStore';
import { newportRounding } from '@services/Utils';

const useStyles = makeStyles({
    labels: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 6,
        padding: '6px',
        [theme.breakpoints.up('md')]: {
            top: 0,
        },
        '&.large': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            top: 10,
            right: 10,
            left: 10,
            gap: 12,
            [theme.breakpoints.up('md')]: {
                alignItems: 'flex-end',
                left: 'initial',
            },
        },
    },
    marketingLabel: {
        background: '#000',
        color: '#fff',
        padding: '4px 10px',
    },
    labelContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2px 10px 1px 10px',
        whiteSpace: 'nowrap',
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '22px',
        background: '#ffffff',
        color: '#000000',
        pointerEvents: 'none',
        width: 'max-content',
        '&.dark': {
        },
        '.large &': {
            fontSize: 13,
            padding: '8px 20px',
        },
    },
    left: {
        gridColumn: 1,
        justifySelf: 'self-start',
    },
    right: {
        gridColumn: 2,
        justifySelf: 'self-end',
    },
});

export default function ProductLabels(
    {
        available,
        streamIds = [],
        propertyIds = [],
        prices,
        large = false,
        deliveryTime = null,
    },
) {
    const appMethods = useApplicationStore((state) => state.methods);
    const sessionActions = useSessionStore((state) => state.methods);
    const classes = useStyles();
    const [ isClient, setIsClient ] = useState(false);

    useEffect(() => {
        // Set to true only when the component has mounted (client-side)
        setIsClient(true);
    }, []);

    if (!isClient) return null; // Don't render on the server

    const hasLightButtons = propertyIds?.includes('12dba8fc3431593d890d5802b96bb751') ? 'light' : false;
    const marketingLabels = appMethods.getMarketingLabels(propertyIds);
    const size = large ? 'large' : 'small';

    function renderLabel(
        label,
        labelClass,
        position = classes.left
    ) {
        const labelStyle = [classes.labelContainer, labelClass, size, position];

        if (hasLightButtons) {
            labelStyle.push(hasLightButtons);
        }

        return <span
            key={`disco-bubble-${label}-${labelClass}`}
            className={labelStyle.join(' ')}
        >
            {label}
        </span>;
    }

    function renderStreamIds(productStreamIds) {
        if (!Array.isArray(productStreamIds) || !(productStreamIds.length > 0)) {
            return null;
        }

        if (!sessionActions.isSalesChannelNordic()) {
            return null;
        }

        if (prices.discount) {
            return null;
        }

        if (!available) {
            return null;
        }

        return null;
    }

    let labels = [];

    if (marketingLabels?.length) {
        marketingLabels.map((label) => {
            labels.push(renderLabel(label, 'marketing'));
        });
    }

    if (deliveryTime && !available) {
        labels.push(renderLabel(deliveryTime?.translated?.name, 'delivery', classes.right));
    }

    if (prices?.discount && available) {
        labels.push(renderLabel(`${newportRounding(prices.discount.percentage)}%`, 'discount', classes.right));
    }

    if (streamIds?.length) {
        labels.push(renderStreamIds(streamIds));
    }


    if (labels?.length <= 0) {
        return null;
    }

    return (<div className={[ classes.labels, size ].join(' ')}>
        {labels}
    </div>);
}
