import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import Like from '../../../public/images/icons/heart-ultrathin.svg';
import { useTranslation } from '../../../tools/i18n';
import theme from '../../theme';
import useUserStore from '../../../services/stores/UserStore';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
    likeButton: {
        display: 'flex',
        position: 'absolute',
        right: 42,
        bottom: 8,
        zIndex: 20,
        marginTop: 20,
        padding: 10,
        minWidth: 0,
        fontSize: 11,
        color: '#000',
        fontWeight: 500,
        textTransform: 'none',
        backgroundColor: 'transparent',
        '& svg': {
            color: '#000',
            fill: 'transparent',
            strokeWidth: 0.25,
            transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '&.active svg': {
            color: '#000',
            fill: '#000',
        },
        '&.light': {
            right: 42,
            backgroundColor: 'transparent',
            '& svg': {
                color: '#fff',
                fill: 'transparent',
                strokeWidth: 0.75,
                filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.9))',
            },
            '&.active svg': {
                color: '#fff',
                fill: '#fff',
            },
        },
        '&.large': {
            position: 'relative',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 0,
            background: theme.button.purchase.backgroundColor,
            borderRadius: 0,
            width: 'auto',
            height: '100%',
            flexShrink: 0,
            aspectRatio: 1,
            '& svg': {
                color: '#fff',
                fill: 'transparent',
                strokeWidth: 0.75,
            },
            '&.active svg': {
                fill: '#fff',
            },
            [theme.breakpoints.down('sm')]: {
                aspectRatio: 'unset',
                padding: '14px',
            },
            [theme.breakpoints.down('xs')]: {
                aspectRatio: 1,
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: 8,
            right: 45,
        },
    },
    likeLoader: {
        position: 'absolute',
        top: 2,
        left: 2,
    },
    purchaseTouchRipple: {
        left: '-15px',
    },
}), { name: 'LikeButton' });

export default function LikeButton(
    {
        show,
        productId,
        hasLightButtons,
        size='small'
    },
) {
    const classes = useStyles();
    const { t } = useTranslation();
    const user = useUserStore();
    const highlighted = user.isHighlighted(productId);

    if (!show) {
        return null;
    }

    return <>
        <Button
            className={[ classes.likeButton, size, hasLightButtons, highlighted ? 'active' : '', 'like' ].join(' ')}
            TouchRippleProps={{ classes: { rippleVisible: classes.purchaseTouchRipple } }}
            onClick={(event) => {
                event.preventDefault();
                event.nativeEvent.preventDefault();

                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();

                if (highlighted) {
                    user.removeFavorite(productId);
                } else {
                    user.addFavorite(productId);
                }
            }}>
            {
                size === 'small' &&
                <Like width={18} height={18} alt={t('buy-short')} />
            }
            {
                size === 'large' &&
                <Like width={20} height={20} alt={t('buy-short')} />
            }
        </Button>
    </>;
}
